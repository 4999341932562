.input-container {
  // margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 5px;
    cursor: pointer;
  }

  &__field {
    position: relative;
    display: flex;
  }
}

.input-validate-colorpicker {
  .chrome-picker .flexbox-fix:last-child {
    display: none !important;
  }
}

.validator-input-wrapper {
  max-width: 100%;
  width: 100%;
}

.validator-input-container {
  width: 100%;
}

.validator-input {
  &.react-inputs-validation__error___2aXSp {
    border-color: #e91e63;
  }
}

.react-inputs-validation__msg_identifier {
  font-size: 12px;
  color: #e91e63;
}

input {
  max-width: 100%;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(34, 36, 38, 0.15);
  background: #fff;
  padding: 10px 14px;
  line-height: 1;
  border-radius: 0.28571429rem;
  outline: 0;
}

.input-label-wrapper {
  display: flex;
  align-items: center;
}

.input-asterisk {
  color: #f44336;
  //font-size: 22px;
  padding-right: 3px;
}
