@keyframes bounce {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  5% {
    transform: translateY(-5px) rotate(-8deg);
  }
  10% {
    transform: translateY(0px) rotate(0deg);
  }
  15% {
    transform: translateY(-2px) rotate(8deg);
  }
  20% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 100px 0;
  }
  to {
    background-position: 0 0;
  }
}
