$root: '/';

@import 'variables';
@import 'media';
@import 'keyframes';
@import 'base';
@import 'grid';

@import 'antd'; // Overrides

// Components
@import '../components/common/Input/style';
@import '../components/Breadcrumbs/style';

.page-wrapper {
  .page-layout__content {
    @import '../components/Header/style';
  }
}

@import '../components/SettingGallery/style';
@import '../components/Tooltip/style';
@import '../components/ValidateInput/style';

@import 'main';
@import 'pages';
