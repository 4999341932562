.header {
  $this: &;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &-logo {
    display: flex;
    align-self: flex-start;
    align-items: flex-end;
    cursor: pointer;

    &__svg {
    }

    &__svg-bg {
      fill: $color-blue;
    }

    &__svg-text {
      fill: $color-white;
    }

    &__svg-dot {
      fill: $color-blue;
    }

    &__title {
      font-size: 22px;
      line-height: 1.6;
      font-weight: bold;
      color: $color-blue;
      text-transform: uppercase;
      position: relative;

      > div {
        position: absolute;
        bottom: 100%;
        left: 0;
        font-size: 0.75em;
        line-height: 1;
        font-weight: normal;
        text-transform: capitalize;
        color: $color-grey;
      }
    }
  }

  &__active-panel {
    display: flex;
    justify-content: flex-end;
    margin-left: $gutter / 2;

    button.button {
      background: none;
      margin-right: -1em;
      color: $color-green;
    }
  }

  &__icon {
    cursor: pointer;
    border-radius: 50%;
    margin-right: 0;

    & + #{$this}__icon {
      margin-left: 5px;
    }

    transition: 400ms;

    &:hover {
      background: #c2c2c2;
    }

    i.icon {
      margin: 0;
      box-shadow: none;
    }
  }

  &__menu-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;

    > i {
      position: relative;
      outline: none;
      cursor: pointer;
    }
  }

  &__menu {
    margin-top: 1em;
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.user-panel {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1em 0 1em 1em;

  &__details {
    margin-left: $gutter / 2;
  }

  &__image {
    width: 36px;
    height: 36px;
    border-radius: 20%;
    overflow: hidden;
    box-sizing: initial;
    background: lighten($color-grey--light, 0.9);
    border: 1px solid $color-grey--light;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
    }

    i {
      margin: 0;
      height: auto;
      opacity: 0.25;
      font-size: 1.25em;
    }
  }

  &__text {
    font-size: $font-size;
  }

  &__role {
    color: $color-grey;
  }
}
